.App {
  font-family: "Lato", sans-serif;
}
p,
span,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li {
  font-family: "Lato", sans-serif;
}

.ant-btn {
  width: 227px;
  height: 64px;
  font-size: 1.2rem;
}

body {
  margin: 0;

  font-family: "Lato", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* main screen style */
.header-option {
  color: black;
}

.sub-title {
  font-size: 20px;
  font-weight: 900;
}
.value-paragraph {
  font-size: 32px;
  font-weight: 900;
  color: #636d5b;
}
.obervations {
  font-size: 16px;
  font-weight: 900;
}
.obervations-weight-400 {
  font-size: 16px;
  font-weight: 400;
}
.ant-row {
  margin-bottom: 3rem;
}
.ant-menu-title-content {
  color: white;
  font-size: 20px;
  font-weight: 900;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ant-layout-sider.ant-layout-sider-dark {
  max-width: 24% !important;
  min-width: 24% !important;
}
.ant-form-item-required {
  font-size: 16px !important;
  font-weight: 400;
}
.css-dev-only-do-not-override-184ih6u.ant-col-24.ant-form-item-label > label {
  font-size: 16px;
}
.ant-btn.css-dev-only-do-not-override-184ih6u.ant-btn-primary,
.ant-btn.css-dev-only-do-not-override-184ih6u.ant-btn-default {
  width: 227px !important;
  height: 50px !important;
  font-size: 16px;
}
.ant-input.css-dev-only-do-not-override-184ih6u.ant-input-outlined,
.ant-input.css-dev-only-do-not-override-184ih6u,
.ant-form-item-control-input,
input.ant-input.css-dev-only-do-not-override-184ih6u,
.ant-input-number-input,
.css-dev-only-do-not-override-184ih6u.ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector
  .ant-select-selection-search-input {
  /* width: 14rem; */
  height: 50px !important;
  font-size: 14px !important;
}
:where(.css-184ih6u).ant-input-affix-wrapper > input.ant-input,
:where(.css-184ih6u).ant-input-affix-wrapper > textarea.ant-input,
:where(.css-184ih6u).ant-input,
:where(.css-1l1hr4k).ant-input {
  height: 50px !important;
  font-size: 14px !important;
}
.ant-input-number-input {
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Accessing button within .header-option class */
.header-option button {
  /* Your styles for the button here */
}

th.ant-table-cell {
  text-align: center !important;
}
.ant-table-cell {
  font-size: 16px;
  font-weight: 400;
}

.ant-table-container {
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.ant-radio-inner {
  background: #d9d9d9 !important;
}
:where(.css-dev-only-do-not-override-dghse4).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #000000 !important;
  border: 2px solid #000000 !important;
}

:where(.css-dev-only-do-not-override-dghse4).ant-btn {
  width: 227px !important;
  height: 64px !important;
  font-size: 2rem;
}

.ant-table-cell {
  font-size: 16px !important;
  font-weight: 400 !important;
}
